<template>
  <div class="main">
    <div class="main-top">
      <img @click="goBack" src="@/assets/image/arrow-left.png" />
      <span>奖品详情</span>
    </div>
    <div class="content-fa" v-if="options">
      <div class="content">
        <div class="card-box">
          <div class="card-left">
            <img :src="options.thumbImageUrl" />
            <div class="name-box">
              <div class="name">{{ options.name }}</div>
              <div class="tips">
                已有{{ options.convertTimes }}人兑换 剩余{{ options.stock }}件
              </div>
            </div>
          </div>
          <div class="card-right">
            <span>{{ options.displayPoints }}</span> 金币
          </div>
        </div>
        <div class="describe" v-html="options.describe"></div>
      </div>
      <div class="btn-fa">
        <div
          @click="exchange"
          :class="['btn', { 'btn-opacity': !options.stock }]"
        >
          立即兑换
        </div>

        <div class="btn2" @click="goUrl">修改支付宝账户</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: null
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    exchange() {
      if (!this.options.stock) {
        this.$dialog.alert({
          title: "提示",
          message: "此奖品库存不足啦，请耐心等待管理员补货哦！"
        });
        return;
      }

      if (this.options.category === "微信红包") {
        this.$dialog
          .confirm({
            title: "提示",
            message:
              "为了您能够正常兑换微信红包，请务必点击页面中“详细兑换规则”链接，并仔细阅读兑换流程！"
          })
          .then(() => {
            let userid = localStorage.getItem("userid") || "";
            this.$http
              .post(`/api/v1/Order`, null, {
                params: {
                  id: userid,
                  giftID: this.options.id
                }
              })
              .then(res => {
                if (res.status === 200) {
                  if (res.data.memo) {
                    this.$toast(res.data.memo);
                  }
                } else {
                  if (res.statusText) {
                    this.$toast(res.statusText);
                  }
                }
              });
          });
        return;
      } else {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.$dialog
          .confirm({
            title: "提示",
            message: `<div>请确认您的支付宝账号和姓名</div><div>支付宝账号：${
              userInfo.alipay ? userInfo.alipay : "未填写"
            }</div><div>姓名：${
              userInfo.name ? userInfo.name : "未填写"
            }</div>`,
            className: "zhifu"
          })
          .then(() => {
            let userid = localStorage.getItem("userid") || "";
            this.$http
              .post(`/api/v1/Order`, null, {
                params: {
                  id: userid,
                  giftID: this.options.id
                }
              })
              .then(res => {
                if (res.status === 200) {
                  if (res.data.memo) {
                    this.$toast(res.data.memo);
                  }
                } else {
                  if (res.statusText) {
                    this.$toast(res.statusText);
                  }
                }
              });
          });
      }
    },
    goUrl() {
      this.$router.push("/mobile/alipay");
    }
  },
  async created() {
    if (this.$route.query.id) {
      const res1 = await this.$http.get(
        `/api/v1/Gifts/${this.$route.query.id}`,
        {
          params: {
            id: this.$route.query.id
          }
        }
      );
      console.log("res1", res1.data);
      if (res1.status === 200) {
        this.options = res1.data;
      }
    }

    if (!localStorage.getItem("userInfo")) {
      if (localStorage.getItem("userid")) {
        let userid = localStorage.getItem("userid");
        const res = await this.$http.get(`/api/v1/Accounts/${userid}`, {
          params: {
            id: userid
          }
        });
        if (res.status === 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
        }
      } else {
        this.$router.push("/mobile");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 40px);
  width: 100vw;
  background-color: #f8f8fa;
  position: relative;
  padding-top: 40px;
  .main-top {
    position: fixed;
    top: 0;
    width: calc(100% - 30px);
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: 15px;
    color: #232323;
    font-size: 16px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    background-color: #f8f8fa;
    img {
      width: 20px;
      position: absolute;
      left: 0;
    }
  }
  .content-fa {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .btn {
      width: 100%;
      height: 46px;
      background: linear-gradient(138deg, #ffa200 0%, #ff6017 100%);
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      line-height: 46px;
      color: #ffffff;
      font-size: 18px;
    }
    .btn-opacity {
      opacity: 0.6;
    }
    .btn-fa {
      margin: 40px 15px;
    }
    .btn2 {
      margin-top: 20px;
      width: 100%;
      height: 46px;
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      line-height: 46px;
      color: #ff6017;
      font-size: 18px;
      border: 1px solid #ff6017;
      box-sizing: border-box;
    }
  }
  .content {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    margin-top: 20px;
    .card-box {
      width: 100%;
      height: 117px;
      background: #ffffff;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.051);
      border-radius: 8px 8px 8px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      .card-left {
        display: flex;
        align-items: center;
        overflow: hidden;
        img {
          width: 80px;
          margin-left: 10px;
          margin-right: 10px;
          flex-direction: 0;
        }
        .name-box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          .name {
            font-size: 16px;
            color: #232323;
            max-width: 100%;
            box-sizing: border-box;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏超出部分 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
          .tips {
            max-width: 100%;
            box-sizing: border-box;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏超出部分 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
            color: #999999;
            font-size: 12px;
            margin-top: 18px;
          }
        }
      }
      .card-right {
        flex-direction: 0;
        margin-right: 10px;
        color: #ff3636;
        font-size: 14px;
        span {
          font-size: 20px;
          font-weight: bolder;
        }
      }
    }
    .describe {
      margin-top: 20px;
    }
  }
}
</style>
<style scoped>
.describe >>> p {
  font-size: 14px !important;
}
.describe >>> span {
  font-size: 14px !important;
}
.describe >>> a {
  display: inline-block;
  margin-top: 20px;
  color: #029dfd;
}
</style>
