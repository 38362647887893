import { render, staticRenderFns } from "./exchangeDetails.vue?vue&type=template&id=db392260&scoped=true&"
import script from "./exchangeDetails.vue?vue&type=script&lang=js&"
export * from "./exchangeDetails.vue?vue&type=script&lang=js&"
import style0 from "./exchangeDetails.vue?vue&type=style&index=0&id=db392260&lang=scss&scoped=true&"
import style1 from "./exchangeDetails.vue?vue&type=style&index=1&id=db392260&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db392260",
  null
  
)

export default component.exports